import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import Layout from '../components/Layout';
import Builder from '../components/Builder';
import NotFound from '../components/NotFound';
import Seo from '../components/Seo';

const Concept = () => {
  const data = useStaticQuery(graphql`
    query PrivacyQuery {
      privacy: allWpPrivacyPolicy {
        nodes {
          builder {
            contentEn {
              text
              imagesPosition
              images {
                localFile {
                  url
                }
              }
            }
            contentFr {
              text
              imagesPosition
              images {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;

  if (!data?.privacy?.nodes || data?.privacy?.nodes.length === 0) {
    return <NotFound />;
  }

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'general.privacyPolicy' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'general.privacyPolicy' })}
      </div>
      <div className="page-title-underline" />
      <Builder
        sections={
          locale === 'en' &&
          data?.privacy?.nodes.length > 0 &&
          data?.privacy?.nodes[0]?.builder?.contentEn?.length > 0
            ? data.privacy.nodes[0].builder.contentEn
            : data?.privacy?.nodes[0]?.builder?.contentFr
        }
      />
    </Layout>
  );
};

export default Concept;
